import { Link } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';
import { useState } from 'react';

// PROJECT DETAIL COMPONENT - Change project globe
const ProjectDetailChange = (props: {
  nextProjectImage: any,                                                  // ->   image data path of next project
  projects: any,                                                          // ->   all projects data from strapi query  
  currentPath: string | undefined,                                        // ->   current page path
}) => {
  
  // --- Setting previous and next projects from all projects data 
  const projectsNumber: number = props?.projects && props.projects?.edges.length;
  const currentIndex: number = props?.projects && props.projects?.edges.findIndex((el: any) => props?.currentPath && (el?.node?.title.toLowerCase() as string).includes(props.currentPath));
  
  const prevIndex: number = (currentIndex === 0) ? (projectsNumber - 1) : (currentIndex - 1);
  const nextIndex: number = ((currentIndex + 1) === projectsNumber) ? 0 : (currentIndex + 1);
  
  const currentProjectName: string = props?.projects && props.projects?.edges[currentIndex] ?.node.title;
  const prevProject: string = props?.projects && props.projects?.edges[prevIndex]?.node.title;
  const nextProject: string = props?.projects && props.projects?.edges[nextIndex]?.node.title;
  const [changeProjectName, setChangeProjectName] = useState<string>(currentProjectName);

  const _project: any = props.projects && props.projects?.edges.filter((el: any) => changeProjectName && (el?.node.title == (changeProjectName as string)))[0]
  const project_images: any = _project && _project?.node.detailDecorationImgs;
  const images: any = project_images && project_images.sort((a: any, b: any) => a?.name > b?.name ? 1 : -1);
  let nextImage: IGatsbyImageData = images && getImage(images[0].localFile);
  // ---
  
  // --- Buttons handler functions ---
  const handleEnter = (direction: 'prev' | 'next') => {
    if (direction === 'next') { 
      setChangeProjectName(nextProject);
    } else if (direction === 'prev') {
      setChangeProjectName(prevProject);
    }
  }

  const handleLeave = () => {
    setChangeProjectName(currentProjectName);
  }
  // --- 
  
  return (
    <div className='relative flex flex-col-reverse lg:flex-row px-6 bg-black text-cardinal-white h-[65vh] lg:h-min lg:px-36 lg:space-x-14 py-7 lg:py-0'>
      <div className='items-center hidden lg:flex'>
        <Link className='lg:px-5 xl:px-6 lg:py-2 xl:py-3 lg:translate-y-[100%] xl:translate-y-[250%] border hover:border-cardinal rounded-full hover:bg-cardinal hover:text-cardinal-white cursor-pointer duration-200'
          to={'/project-detail/' + changeProjectName?.toLowerCase()}
          onMouseEnter={() => handleEnter('prev')}
          onMouseLeave={() => handleLeave()}>
            PREV WORK
        </Link>
      </div>
      <div className='relative flex items-center justify-center flex-1 h-full lg:h-auto lg:items-end'>
        <div id='changeProjectName' className='absolute z-20 uppercase duration-[50ms] text-black text-4xl lg:text-8xl lg:text-[85px] h-min w-min'>
          <span className='opacity-75'>{changeProjectName}</span>
        </div>
        <GatsbyImage image={nextImage} alt={'project'} className="w-2/3 lg:w-3/4 lg:bottom-[-17%] 3xl:bottom-[-20%] title rounded-full aspect-square" />
        <div className='absolute w-4/5 lg:w-5/6 lg:bottom-[-23%] aspect-square 3xl:bottom-[-26%] rounded-full border-cardinal-white border-[1px]' />
      </div>
      <div className='z-30 flex justify-end w-full lg:w-max lg:items-center'>
        <Link className='p-3 lg:px-5 xl:px-6 lg:py-2 h-max xl:py-3 border hover:border-cardinal rounded-full lg:translate-y-[100%] xl:translate-y-[250%] hover:bg-cardinal hover:text-cardinal-white cursor-pointer duration-200'
          to={'/project-detail/' + changeProjectName?.toLowerCase()}
          onMouseEnter={() => handleEnter('next')}
          onMouseLeave={() => handleLeave()}>
            NEXT WORK
        </Link>
      </div>
    </div>
  )
}

export default ProjectDetailChange;